import React from "react";
import { color, motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";
import header from "../../assets/computer.png";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="info">
        <span style={{ color: "#a9b3c4", fontSize: "40px" }}>
          Hello I am <h2 style={{ color: "#fff", fontSize: "50px" }}>BURAK</h2>
        </span>
        <span style={{ color: "#a9b3c4", fontSize: "20px" }}>
          I'm a software engineer from Türkiye, and this is my portfolio
          website. Thank you for visiting, and I hope you enjoy exploring my
          portfolio.
        </span>
        <br />
        <div className="button">
          <a className="hire" href="#contact">
            Contact Me
          </a>
          <a className="resume" href="#resume">
            Resume
          </a>
        </div>
      </div>
      <div className="header_img">
        <img src={header} alt="header" />
      </div>
    </motion.div>
  </div>
);

export default AppWrap(Header, "home");
